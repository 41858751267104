<template>
  <div class="my-4">
    <AdsenseMain1 />
  </div>

  <AppModal id="wordDetailModal" title="Chi tiết từ vựng" @close="closeModal" :max-width="600">
    <WordDetail :word="wordDetail" />
  </AppModal>

  <div v-if="store.ready" class="category-show bg-white px-2 lg:px-4 py-8">
    <h1 class="category-title">
      Từ vựng IT chủ đề {{ wordStore.category.name_vi }}
    </h1>

    <p class="text-center mt-4 mb-12 mx-auto max-w-2xl">{{ wordStore.category.description_vi }}</p>

    <div v-if="wordStore.category.layout_name === 'Image'" class="grid grid-cols-12 gap-4 lg:gap-6">
      <WordLayoutImage
        v-for="word in wordStore.category.words"
        :key="word.id"
        :categorySlug="wordStore.category.slug"
        :word="word"
        @view-detail="viewDetail"
      />
    </div>

    <div v-else-if="wordStore.category.layout_name === 'Color'" class="grid grid-cols-12 gap-4 lg:gap-6">
      <WordLayoutColor
        v-for="word in wordStore.category.words"
        :key="word.id"
        :categorySlug="wordStore.category.slug"
        :word="word"
      />
    </div>

    <div v-else-if="wordStore.category.layout_name === 'Character'" class="grid grid-cols-12 gap-4 lg:gap-6">
      <WordLayoutCharacter
        v-for="word in wordStore.category.words"
        :key="word.id"
        :categorySlug="wordStore.category.slug"
        :word="word"
      />
    </div>

    <div v-else-if="wordStore.category.layout_name === 'Name'" class="grid grid-cols-12 gap-4 lg:gap-6">
      <WordLayoutName
        v-for="word in wordStore.category.words"
        :key="word.id"
        :categorySlug="wordStore.category.slug"
        :word="word"
        @view-detail="viewDetail"
      />
    </div>

    <table v-else-if="wordStore.category.layout_name === 'Computerese'" class="w-full">
      <WordLayoutComputerese
        v-for="word in wordStore.category.words"
        :key="word.id"
        :categorySlug="wordStore.category.slug"
        :word="word"
      />
    </table>

    <table v-else>
      <WordLayoutNormal
        v-for="word in wordStore.category.words"
        :key="word.id"
        :categorySlug="wordStore.category.slug"
        :word="word"
        @view-detail="viewDetail"
      />
    </table>

    <AppPagination class="mx-auto mt-8 w-max" @change-page="changePage" />
  </div>

  <AppLoading v-else class="my-20"/>

  <div class="mt-4">
    <AdsenseMain1 />
  </div>

  <div class="my-8 p-4 bg-blue-50 rounded-md">
    <div class="">{{ $t('comment.ifYouHaveAnyWord') }} 😊</div>
    <CategoryFacebookComment :url="currentUrl" />
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import { useStore } from '$/stores'
import { useWordStore } from '$/stores/wordStore'
import { useRoute, useRouter } from 'vue-router'
import { useHead } from "@unhead/vue";
import { Modal } from "flowbite";
import AppPagination from '&/AppPagination.vue'
import AppModal from '&/AppModal.vue'
import WordLayoutImage from '&/WordLayoutImage.vue'
import WordLayoutColor from '&/WordLayoutColor.vue'
import WordLayoutCharacter from '&/WordLayoutCharacter.vue'
import WordLayoutName from '&/WordLayoutName.vue'
import WordLayoutComputerese from '&/WordLayoutComputerese.vue'
import WordLayoutNormal from '&/WordLayoutNormal.vue'
import WordDetail from '&/WordDetail.vue'
import CategoryFacebookComment from '&/CategoryFacebookComment.vue'
import AdsenseMain1 from '&/AdsenseMain1.vue'
import {useFlowbite} from "$/composables/useFlowbite";
import AppLoading from "&/AppLoading.vue";

const props = defineProps<{
  slug: string
}>()

const store = useStore()
const wordStore = useWordStore()
const route = useRoute()
const router = useRouter()
const currentUrl = computed(() => `${window.location.origin}${window.location.pathname}`)

onBeforeMount(() => {
  store.ready = false
  wordStore.page.currentPage = parseInt(<string>route.query.page) || 1
})

onMounted(async () => {
  initFB().catch((err) => console.warn(err))
  window.scrollTo(0, 0)

  await wordStore.fetchCategory(props.slug)
  store.ready = true
  useFlowbite(['modals'])

  useHead({
    title: `Từ Vựng IT Tiếng Nhật Chủ Đề ${wordStore.category.name_vi} | TiengNhatIT.com`,
    meta: [
      {
        name: "description",
        content: wordStore.category.description_vi,
      },
    ]
  })
})

const changePage = function (page) {
  wordStore.page.currentPage = page
  router.push({ name: 'CategoryShow', query: { page: page } })
}

const initFB = async function () {
  await fbAsyncInit() // eslint-disable-line
}

const wordDetail = ref({})
const modal = ref(null)

const viewDetail = function (wordId) {
  modal.value = new Modal(document.getElementById('wordDetailModal'),{}, {})
  wordDetail.value = wordStore.category.words.find((i) => i.id === wordId)
  modal.value.show()
}

function closeModal() {
  modal.value.hide()
}
</script>
